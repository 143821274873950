

<template>
  <div class="left_menu_cus">
    <router-link to="/front/recharge" tag="span" class="m1bg" @click.native="mobileMenuAction"><i class="iconfont icon-chongzhi btn-icon-normal"></i>충전</router-link>
    <router-link to="/front/exchange" tag="span" class="m1bg lrb_border" @click.native="mobileMenuAction"><i class="iconfont icon-tikuan btn-icon-normal"></i>환전</router-link>
    <router-link  to="/front/customercenter" tag="span" class="m1bg" @click.native="mobileMenuAction"><i class="iconfont icon-kefu btn-icon-small"></i>문의</router-link>

    <router-link to="/front/sports" tag="span" @click.native="mobileMenuAction">스포츠</router-link>
    <router-link to="/front/sports_special" tag="span" @click.native="mobileMenuAction" class="lrb_border">스페셜</router-link>
    <router-link to="/front/inplay" @click.native="mobileMenuAction" tag="span">인플레이</router-link>

    <router-link to="/front/minigame/eospowerball1m" @click.native="mobileMenuAction" tag="span">미니게임</router-link>
    <router-link to="/front/casino" @click.native="mobileMenuAction" tag="span" class="lrb_border">카지노</router-link>
    <router-link to="/front/slot" @click.native="mobileMenuAction" tag="span" >슬롯</router-link>

    <router-link to="/front/notice" @click.native="mobileMenuAction" tag="span">공지사항</router-link>
    <router-link to="/front/event" @click.native="mobileMenuAction" tag="span"  class="lrb_border">이벤트</router-link>
    <router-link to="/front/sportsbethistory" @click.native="mobileMenuAction" tag="span">베팅내역</router-link>
  </div>
</template>
<script>
export default {
  name: "LeftMenuLinkComp",
  methods:{
    mobileMenuAction(){
      this.$bus.$emit('activeLeftMenu', false);
    }
  }
}
</script>
<style scoped>

</style>