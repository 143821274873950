

<template>
  <div class="menu_title" style="">
    <slot></slot>
  </div>

</template>
<script>
export default {
  name: "LeftMenuTitle"
}
</script>
<style scoped>
  .menu_title{
    width: 100%;text-align: left;padding-left: 1rem;color: #fff9ff;margin: 5px 0 0 0
  }
</style>