

<template>
  <div class="banner">
    <a href="https://t.me/yewtoto" target="_blank">
      <img alt="" src="../../../assets/images/banner/tel.gif">
    </a>
<!--    <a href="https://t.me/yewtoto" target="_blank">
      <img alt="" src="../../../assets/images/banner/tel.gif">
    </a>-->
  </div>

</template>
<script>
export default {
  name: "RightBannerComp"
}
</script>
<style scoped>
  .banner{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

  }
  .banner a{}
  .banner img{
    width: 100%;

  }
</style>