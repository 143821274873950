<template>
  <div class="login_wrap">
    <div class="ipt">
      <i class="fa fa-user"></i>
      <input type="text" v-model="user.username" placeholder="아이디를 입력하세요">
    </div>
    <div class="ipt">
      <i class="fa fa-lock"></i>
      <input type="password" v-model="user.passwd"  @keyup.enter="doLogin"  placeholder="비밀번호를 입력하세요">
    </div>
    <div class="btn">
      <button type="button" class="" @click="doLogin" >로그인</button>
      <button type="button" class="" @click="showRegisterPanel">회원가입</button>
    </div>
  </div>
</template>
<script>
import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "@/store/mutation-types";
import {changePoint, getUserInfo, login, logout} from "@/network/userRequest";
import {getAccountBalance, getAccountBalance2} from "@/network/casinoHonorRequest";

export default {
  name: "RightUserLoginComp",
  data() {
    return {
      user: {
        username: '',
        passwd: '',
      },
    }
  },
  methods: {
    doLogin() {
      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          getUserInfo().then(res => {
            this.$store.state.isLogin = true;
            const userInfo = res.data.data
            this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            window.sessionStorage.setItem("isLogin", 'true');
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.$router.push('/front/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    logout() {
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$store.state.isLogin = false;
          this.$router.replace("/main")
          this.$store.state.uncheckedMessageCount = 0;
          logout();
        }
      })

    },
    initUserInfo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      this.$store.dispatch('actionUserInfo')
      setTimeout(() => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
      }, 1000)
    },
    changePoint() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePoint().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (!res.data.success) {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.initUserInfo()
        }
      })
    },
    refreshCasinoCash() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
      })
    },
    refreshCasinoCash2() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getAccountBalance2().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.$set(this.$store.state.userInfo, 'slotcash', res.data.data)
      })
    },
    showRegisterPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
      }
    },
  },
  created() {

  }
}
</script>
<style scoped>
.login_wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.login_wrap .ipt {
  width: 100%;
  margin-bottom: 3px;
  position: relative;
}

.login_wrap .ipt input {
  width: 100%;
  height: 3em;
  font-size: 1.2rem;
  border-radius: 1px;
  border: 1px solid #333335;
  background-color: #000000;
  text-align: center;
  color: #b7b6b6;
}

.login_wrap .ipt i {
  position: absolute;
  color: #808080;
  top: .82rem;
  left: 1.2rem;
  font-size: 1.8rem;
}

.login_wrap .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.login_wrap .btn button {
  width: 49.5%;
  height: 3.2rem;
  margin-top: .5rem;
  border: 0 solid #6d6d6d;
  background-image: linear-gradient(360deg, #b3b3b3 0, #fff);
  font-size: 1.5rem;
  border-radius: .2rem;
  color: #000;
  font-weight: 700;
  cursor: pointer;
}
</style>